import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import {
  Box, Container, Stack, Typography,
} from '@mui/material';
import { getLessonContentNew, getVocabForLesson, setKnownVocabAndGetNew } from 'actions/languageModelActions';
import React, { useEffect, useState } from 'react';
import { VocabCard } from 'commons/VocabCard';
import theme from 'theme/theme';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MainButton } from 'commons/MainButton';
import { Navbar } from 'commons/Navbar';
import { WordSelectorDialog } from 'pages/main/WordSelectorDialog';
import { SkeletonVocabSelector, SkeletonVocabcard } from 'animations/SkeletonVocabSelector';

export const VocabSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getToken } = useKindeAuth();
  const lessonType = useSelector((state) => state.lesson.exerciseDetails.exerciseName);
  const vocabList = useSelector((state) => state.lesson.vocabList);
  const loadingState = useSelector((state) => state.lesson.loadingState); // loading state for the vocab selector component
  const [selectedWord, setSelectedWord] = useState(null);
  const handleClickOpen = (idx) => {
    setSelectedWord(idx);
    console.log(vocabList[idx]);
  };

  const handleClose = () => {
    setSelectedWord(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getLessonContentNew({ getToken, lessonType, vocabList }));
    navigate('/VerbalLesson');
  };

  useEffect(() => {
    dispatch(getVocabForLesson({ getToken }));
  }, [dispatch, getToken]);

  const handleUpdateWord = (idx) => {
    console.log('update word:', idx);
    const vocabToReplace = vocabList[idx];

    dispatch(setKnownVocabAndGetNew({ getToken, idx, vocabToReplace }));
    setSelectedWord(null);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: '100vh', padding: '10px', overflow: 'auto', display: 'flex', flexDirection: 'column',
      }}
    >

      <Box sx={{
        padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '2rem', textAlign: 'center', height: '100%', flexGrow: 1,
      }}
      >

        <Navbar pageTitle="Select words" />

        <Typography variant="body1" sx={{ alignSelf: 'flex-start', textAlign: 'justify' }}>
          <Box component="span" sx={{ display: 'block', marginBottom: '0.5em' }}>
            Here are the words you&apos;ll practice in the next lesson.
          </Box>
          <Box component="span">
            Tap the icon if you know the word or want to choose a different one.
          </Box>
        </Typography>
        {loadingState === 'LOADED'
          ? (
            <>
              {vocabList?.map((word, idx) => (

                <React.Fragment key={idx}>
                  <WordSelectorDialog key={`dialog-${idx}`} handleClose={handleClose} handleUpdateWord={() => handleUpdateWord(idx)} open={selectedWord === idx} />

                  <Stack direction="column" key={idx} sx={{ justifyContent: 'space-between' }}>
                    {word.loading_state ? <SkeletonVocabcard />
                      : <VocabCard handleClickOpen={handleClickOpen} idx={idx} usage={word.vocab} translation={word.translation} icon="shuffle" />}
                  </Stack>

                </React.Fragment>

              ))}
              <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '100%',
              }}
              >
                <MainButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 2,
                    background: '#CC9CD8',
                    width: '100%',
                    borderRadius: '10px',
                    color: 'black',
                    backgroundColor: theme.palette.customColors.green.main,
                    marginBottom: '0',
                  }}
                  onClick={handleSubmit}
                >
                  Let&apos;s start!
                  {' '}
                  <KeyboardArrowRightIcon />
                </MainButton>
              </Box>
            </>
          )
          : <SkeletonVocabSelector />}
      </Box>

    </Container>
  );
};
