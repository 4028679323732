/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ORCHESTRATED_STATES } from 'constants/enums';
import { useDispatch } from 'react-redux';
import { completeCardAction, startCardAction } from 'reducers/lessonSlice';
import { useRef } from 'react';
import { Box } from '@mui/material';
import { TTSSpeechBubble } from 'pages/lesson/components/TTS/TTSSpeechBubble';
import { AudioPlayer } from './AudioPlayer';
import { useTTSCore } from '../../hooks/useTTSCore';

export const ExampleUsageTTS = ({
  idx, orchestrated_state, content,
}) => {
  const dispatch = useDispatch();
  const { sentence, translation } = content;

  const handleClick = (e) => {
    console.log(e);
    dispatch(startCardAction(idx));
    if (orchestrated_state === ORCHESTRATED_STATES.STARTED) { // temporary fix to complete the card
      dispatch(completeCardAction(idx));
    }
  };

  const audioRef = useRef(null);
  useTTSCore(audioRef, orchestrated_state, sentence, (error) => console.log(error), idx);

  return (
    <Box
      tabIndex="0"
      onClick={handleClick}
      className={orchestrated_state === ORCHESTRATED_STATES.STARTED ? 'activated' : ''}
      sx={{
        maxWidth: 'max-content',
      }}
    >
      <AudioPlayer ref={audioRef} />
      <TTSSpeechBubble usage={sentence} translation={translation} />
    </Box>
  );
};
