const { createTheme } = require('@mui/material');

const theme = createTheme({
  palette: {
    primary: {
      main: '#E3DED3', // light beige
      contrastText: '#000000',
    },
    secondary: {
      main: '#DDD1B5',
      contrastText: '#000000',
    },
    // error: {
    //   main: '#F2CB04',
    //   contrastText: '#000000',
    // },
    // warning: {
    //   main: '#FE5D6E',
    //   contrastText: '#000000',
    // },
    // info: {
    //   main: '#F1EDE4',
    //   contrastText: '#000000',
    // },
    // success: {
    //   main: '#E3DED3',
    //   contrastText: '#000000',
    // },
    // text: {
    //   primary: '#DDD1B5',
    // },
    customColors: {
      purple: {
        light: '#E6D2E8',
        main: '#CC9CD8',
        dark: '#552362',
      },
      green: {
        light: '#96D4B8',
        main: '#63C094',
        dark: '#3F8F70',
      },
      yellow: {
        light: '#F5E35B',
        main: '#F2CB04',
        dark: '#B59803',
      },
      cherry: {
        light: '#FF8A9A',
        main: '#FE5D6E',
        dark: '#C74352',
      },
      beige: {
        light: '#F1EDE4',
        main: '#E3DED3',
        dark: '#DDD1B5',
      },
      background: '#DDD1B5', // beige light background
    },
  },
  typography: {
    fontFamily: 'Poppins Medium, sans-serif',
    // Page titles / button text / Main word in word boxes:
    h1: {
      fontSize: '1.25rem',
      fontFamily: 'Poppins Medium, sans-serif',
    },
    // instruction text:
    h2: {
      fontSize: '1rem',
      fontFamily: 'Poppins Medium, sans-serif',
      margin: '8px 0',
    },
    // exercise names:
    h3: {
      fontSize: '1rem',
      fontFamily: 'Poppins Semi Bold, sans-serif',
      margin: '5px',
    },
    // text eg: chat messages, english word in word boxes:
    body1: {
      fontSize: '1rem',
      fontFamily: 'Poppins Regular, sans-serif',
    },
    // eg:exercise description
    subtitle1: {
      fontSize: '0.75rem',
      lineHeight: '1.4',
      fontFamily: 'Poppins Regular, sans-serif',
      textJustify: 'inter-word',
      color: '#252526',
    },
    subtitle2: {
      fontSize: '0.75rem',
      lineHeight: '1.4',
      fontFamily: 'Poppins Regular, sans-serif',
      textJustify: 'inter-word',
      color: '#464137',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});
export default theme;
