/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { getPronounciationFeedback } from 'actions/languageModelActions';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { interruptASR } from 'reducers/lessonSlice';
import { ORCHESTRATED_STATES, ASR_INTERNAL_STATES, CARD_TYPES } from 'constants/enums';
import { useEffect } from 'react';
import { useASRCore } from 'pages/lesson/hooks/useASRCore';
import { ASRFeedbackSpeechBubble } from 'pages/lesson/components/ASR/ASRFeedbackSpeechBubble';
import { BaseConversationBubble } from 'commons/BaseConversationBubble';

export const PronunciationASR = ({
  idx, orchestrated_state, content, type,
}) => {
  const dispatch = useDispatch();
  const { getToken } = useKindeAuth();

  useEffect(() => {
    if (type === CARD_TYPES.ASR_AND_FEEDBACK_EXAMPLE_USAGE_CARD && content.internal_state === ASR_INTERNAL_STATES.COMPLETE_ASR_GET_FEEDBACK) {
      dispatch(getPronounciationFeedback({
        getToken, idx, content,
      }));
    }
  }, [content.internal_state]);

  useEffect(() => {
    if (orchestrated_state === ORCHESTRATED_STATES.INTERRUPTED) {
      dispatch(interruptASR({ idx }));
    }
  }, [orchestrated_state]);
  useASRCore({
    state: content.internal_state, idx,
  });
  return (
    <Box
      sx={{
        width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'flex-end', gap: '20px',
      }}
    >
      {content.asr_output && !content.asr_output.includes('ERROR') && <BaseConversationBubble user usage={content.asr_output} translation="" />}
      {content.asr_output && content.asr_output.includes('ERROR') && <BaseConversationBubble usage={content.asr_output} />}
      {Array.isArray(content.feedback) ? <ASRFeedbackSpeechBubble feedbackText={content.feedback} usage={content.reference_sentence.sentence} /> : null}
    </Box>
  );
};
