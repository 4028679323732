/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ORCHESTRATED_STATES } from 'constants/enums';
import { useDispatch } from 'react-redux';
import { completeCardAction, startCardAction } from 'reducers/lessonSlice';
import { useRef } from 'react';
import { Box } from '@mui/material';
import { VocabCard } from 'commons/VocabCard';
import { AudioPlayer } from './AudioPlayer';
import { useTTSCore } from '../../hooks/useTTSCore';

export const VocabTTS = ({
  idx, orchestrated_state, content,
}) => {
  const dispatch = useDispatch();
  const { vocab, translation } = content;

  const handleClick = (e) => {
    console.log(e);
    dispatch(startCardAction(idx));
    if (orchestrated_state === ORCHESTRATED_STATES.STARTED) { // temporary fix to complete the card
      dispatch(completeCardAction(idx));
    }
  };

  const audioRef = useRef(null);
  useTTSCore(audioRef, orchestrated_state, vocab, (error) => console.log(error), idx);

  return (
    <Box
      tabIndex="0"
      onClick={handleClick}
      className={orchestrated_state === ORCHESTRATED_STATES.STARTED ? 'activated' : ''}
      sx={{ cursor: 'pointer' }}
    >
      <AudioPlayer ref={audioRef} />
      <VocabCard usage={vocab} translation={translation} icon="loudspeaker" />
    </Box>
  );
};
