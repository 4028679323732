import {
  Dialog, DialogActions, Stack, Typography,
} from '@mui/material';
import { MainButton } from 'commons/MainButton';
import theme from 'theme/theme';

export const WordSelectorDialog = ({ handleClose, handleUpdateWord, open }) => (
  <>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title"> */}
      <Stack sx={{ padding: '1.5rem' }}>
        <Typography variant="h2" textAlign="center">Would you like to swap out this word or exclude it from future suggestions? You can always restore excluded words through your profile settings.</Typography>
        {/* </DialogTitle> */}
      </Stack>
      <DialogActions direction="row" spacing={2} sx={{ justifyContent: 'space-evenly', padding: '1rem' }}>
        <MainButton
          onClick={handleUpdateWord}
          autoFocus
          sx={{
            padding: 2,
            width: '80px',
            height: '40px',
            borderRadius: '10px',
            color: 'black',
            backgroundColor: theme.palette.customColors.green.main,
            marginBottom: '0',
          }}
        >
          Swap
        </MainButton>
        <MainButton
          onClick={handleClose}
          sx={{
            padding: 2,
            width: '80px',
            height: '40px',
            borderRadius: '10px',
            color: 'black',
            backgroundColor: theme.palette.customColors.background,
            marginBottom: '0',
          }}
        >
          Exclude
        </MainButton>
      </DialogActions>
      {/* <Stack sx={{ justifyContent: 'center', textDecoration: 'underline' }}>
        <Button sx={{ color: '#464137', fontSize: '0.875rem' }} variant="text"> Only swap, and don&apos;t ask me again</Button>
        <Typography component="button" variant="subtitle2" sx={{ color: '#464137' }}>Only swap, and don&apos;t ask me again</Typography>
      </Stack> */}
    </Dialog>
  </>
);
