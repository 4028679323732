import React from 'react';
// import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Button, Paper, Stack } from '@mui/material';
import Image from 'mui-image';
import theme from 'theme/theme';

export const VocabCard = ({
  usage, translation, icon, handleClickOpen, idx,
}) => (

  <Paper
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 2,
      background: theme.palette.customColors.purple.main,
      width: '100%',
      borderRadius: '10px',
      position: 'relative',
    }}
    elevation={3}
  >
    <Stack sx={{ maxWidth: '90%', textAlign: 'start' }}>
      <Typography sx={{ color: theme.palette.customColors.purple.dark }} variant="h1">
        {usage}
      </Typography>
      <Typography variant="subtitle1">
        {translation}
      </Typography>
    </Stack>
    <Button
      onClick={() => handleClickOpen(idx)}
      sx={{

        borderRadius: '0 10px 10px 0',
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
        background: '#B37EC0',
      }}
    >
      <Image src={`/images/lesson/${icon}.svg`} alt={icon} width="30px" height="30px" sx={{ color: '#B37EC0', transitionDuration: '0s !important' }} />
    </Button>
  </Paper>
);
