import { createBrowserRouter } from 'react-router-dom';
import { StartPage } from 'pages/starter/StartPage.jsx';
import { FinalPage } from 'pages_OLD/FinalPage.js';
// import VocabularyForm from 'pages_OLD/vocabForm.js';
import { Lesson } from 'pages/lesson/Lesson';
import { Main } from 'pages/main/Main';
import { VocabSelector } from 'pages/vocab-selector/VocabSelector';
// import VerbalLesson from 'pages/VerbalLesson.js';

export const router = createBrowserRouter([

  {
    path: '/',
    element: <StartPage />,
  },
  {
    path: '/vocabulary',
    element: <Main />,
  },
  {
    path: '/vocabularyselector',
    element: <VocabSelector />,
  },
  {
    path: 'summary',
    element: <FinalPage />,
  },
  {
    path: 'VerbalLesson',
    element: <Lesson />,
  },
]);
