import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import Image from 'mui-image';

export const TTSSpeechBubble = ({ usage, translation, user }) => (
  <Paper
    sx={{
      padding: 2,
      wordWrap: 'break-word',
      marginLeft: user ? 'auto' : '0',
      marginRight: user ? '0' : 'auto',
      // backgroundColor: '#CC9CD8',
      background: (theme) => theme.palette.primary.main,
      borderRadius: user ? '20px 20px 0 20px' : '20px 20px 20px 0',
      maxWidth: '90%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 2,
      cursor: 'pointer',
    }}
    elevation={3}
  >
    <Stack sx={{ gap: 1, alignItems: 'flex-start' }}>
      <Typography variant="body1">
        {usage}
      </Typography>
      <Typography variant="subtitle1">
        {translation}
      </Typography>
    </Stack>
    <Image src="/images/lesson/loudspeaker.svg" alt="loudspeaker" width="30px" sx={{ color: '#000' }} />
  </Paper>
);
