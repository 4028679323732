import { Stack } from '@mui/material';
import { Animation } from 'commons/Animation';
import { BaseConversationBubble } from 'commons/BaseConversationBubble';
import { MainButton } from 'commons/MainButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme/theme';
import loading from '../../animations/completed-lesson.json';

export const CompletionCard = ({ content }) => {
  const navigate = useNavigate();
  const [showPlayer, setShowPlayer] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPlayer(true);
    }, 1000);
  }, []);

  return (

    <Stack spacing={3}>
      {showPlayer && <Animation size="100%" src={loading} autoplay loop={false} />}
      <BaseConversationBubble usage={content.end_of_lesson_text} background={theme.palette.customColors.green.main} />
      <MainButton variant="contained" background={theme.palette.customColors.purple.main} hover="#F6DEFC" onClick={() => navigate('/vocabulary')}>Back to the homescreen</MainButton>
    </Stack>

  );
};
