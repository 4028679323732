import { createSlice } from '@reduxjs/toolkit';
import {
  getAnswerFeedback, getLessonContentNew, getPronounciationFeedback, getVocabForLesson, setKnownVocabAndGetNew,
} from 'actions/languageModelActions';
import {
  LOADING_STATES, ORCHESTRATED_STATES, ASR_INTERNAL_STATES,
} from 'constants/enums';

// import { selectASRContentBuffer } from './globalSlice';
/**
 * Example state
const cards = [
  1: {
    idx: 1,
    orchestrated_state: ORCHESTRATED_STATES.STAND_BY,
    type: CARD_TYPES.INTRODUCTION,
    content: {
      vocab: 'skog',
      translation: 'forest',
    },
  },
];
 */

const initialState = {
  vocabList: [],
  cards: [],
  latestActiveCardId: null, // Keep track of the latest active card to resume to
  loadingState: LOADING_STATES.STAND_BY,
  error: null,
  create_at: null,
  completed: false,
  user: null,
  asrRecordingHandler: null, // not used yet
  exerciseDetails: {
    exerciseId: 0,
    exerciseName: 'hybrid',
  },
  languageDetails: {
    levelId: 0,
    levelName: 'Beginner',
  },
};

// const buildNewCard = (id, type, content) => ({
//   id,
//   orchestrated_state: ORCHESTRATED_STATES.STAND_BY,
//   internal_state: ASR_INTERNAL_STATES.STAND_BY, --> This is only needed for ASR modules
//   type,
//   content,
// });

export const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    setVocabList: (state, action) => {
      state.vocabList = action.payload;
    },
    setExerciseDetails: (state, action) => {
      const { exerciseId, exerciseName } = action.payload;
      state.exerciseDetails.exerciseId = exerciseId;
      state.exerciseDetails.exerciseName = exerciseName;
    },
    setLanguageDetails: (state, action) => {
      const { levelId, levelName } = action.payload;
      state.languageDetails.levelId = levelId;
      state.languageDetails.levelName = levelName;
    },
    /** Main Orchestrator Logic Begins */
    completeCardAction: (state, action) => {
      // Set the orchestrated state of the card to completed, usually triggered by ASR or TTS module
      const idx = action.payload;
      console.log('completeCardAction:', state.cards[idx].orchestrated_state);
      state.cards[idx].orchestrated_state = ORCHESTRATED_STATES.COMPLETED;

      // ASR component, set internl state to completed
      if (state.cards[idx].content.internal_state && state.cards[idx].content.internal_state === ASR_INTERNAL_STATES.FEEDBACK_LOADED) {
        state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.COMPLETED;
      }

      // When one card is completed, either resume the previous card or start the next card
      if (idx >= state.latestActiveCardId) {
        // Nominal case, if the current card is the latest active card, then start the next card
        state.latestActiveCardId = idx;
        const nextId = idx + 1;
        if (state.cards[nextId]) { // Check if there is a next card
          state.cards[nextId].orchestrated_state = ORCHESTRATED_STATES.STARTED;
        }
      } else {
        // If the current card is not the latest active card, then resume the latest active card
        const idxToResume = state.latestActiveCardId + 1;
        if (state.cards[idxToResume]) {
          state.cards[idxToResume].orchestrated_state = ORCHESTRATED_STATES.STARTED;
        }
      }
    },

    startCardAction: (state, action) => {
      // If there is an existing module running, interrupt it and start the new module
      state.cards.forEach((card) => {
        if (card.orchestrated_state === ORCHESTRATED_STATES.STARTED) {
          card.orchestrated_state = ORCHESTRATED_STATES.INTERRUPTED;
        }
      });
      // Set the orchestrated state of the card to started, usually triggered by user click
      const idx = action.payload;
      state.cards[idx].orchestrated_state = ORCHESTRATED_STATES.STARTED;
    },
    // ASR Actions
    startRecording: (state, action) => {
      const { idx } = action.payload;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.STARTED_RECORDING;
      state.cards[idx].content.asr_output = '';
      state.cards[idx].content.feedback = '';
      console.log('startRecording', idx);
    },
    setASRText: (state, action) => {
      // Meant to transfer from the global ASR Buffer to individual vocabContent's ASR
      const { idx, asrResult } = action.payload;
      console.log(action.payload);
      state.cards[idx].content.asr_output = asrResult;
    },
    completeASR: (state, action) => {
      const { idx } = action.payload;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.COMPLETE_ASR_GET_FEEDBACK;
    },
    interruptASR: (state, action) => {
      const { idx } = action.payload;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.STAND_BY;
    },

    // setFeedbackLoadingState: (state, action) => {
    // rename state key to l_state to avoid confusion with redux state
    // const { id, isLoading } = action.payload;
    // state.cards[id].feedbackIsLoading = isLoading;
    // },
    resetFeedback: (state, action) => {
      const { idx } = action.payload;
      state.vocabContent[idx].feedback = '';
      console.log(idx);
    },
    setPlayBeedSound: (state, action) => {
      state.isBeepPlaying = action.payload;
    },
  },
  extraReducers: {
    /// Async function call to load the lesson content
    [getVocabForLesson.pending]: (state) => {
      state.loadingState = LOADING_STATES.LOADING;
    },
    [getVocabForLesson.fulfilled]: (state, action) => {
      // iterate through action payload dictionary
      const data = action.payload;
      // TODO Allow for updating a single Vocab
      state.loadingState = LOADING_STATES.LOADED;
      state.vocabList = data;
    },

    [getLessonContentNew.pending]: (state) => {
      state.loadingState = LOADING_STATES.LOADING;
    },
    [getLessonContentNew.fulfilled]: (state, action) => {
      // iterate through action payload dictionary
      const data = action.payload;
      state.cards = data.cards;
      state.created_at = data.created_at;
      state.user = data.user;
      state.latestActiveCardId = 0;
      state.loadingState = LOADING_STATES.LOADED;
    },
    [getLessonContentNew.rejected]: (state, action) => {
      state.loadingState = LOADING_STATES.ERROR;
      state.error = action.error;
    },

    /// Async function to call the pronounciation feedback API
    [getPronounciationFeedback.pending]: (state, action) => {
      const { idx } = action.meta.arg;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.FEEDBACK_LOADING;
    },
    [getPronounciationFeedback.fulfilled]: (state, action) => {
      console.log('getPronounciationFeedback.fulfilled:', action.payload);
      const { idx, feedback } = action.payload;
      state.cards[idx].content.feedback = feedback;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.FEEDBACK_LOADED;
    },
    [getPronounciationFeedback.rejected]: (state, action) => {
      const { idx } = action.meta.arg;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.ERROR;
    },

    [getAnswerFeedback.pending]: (state, action) => {
      const { idx } = action.meta.arg;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.FEEDBACK_LOADING;
    },
    [getAnswerFeedback.fulfilled]: (state, action) => {
      console.log('getAnswerFeedback.fulfilled:', action.payload);
      const { idx, feedback } = action.payload;
      state.cards[idx].content.feedback = feedback;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.FEEDBACK_LOADED;
    },
    [getAnswerFeedback.rejected]: (state, action) => {
      const { idx } = action.meta.arg;
      state.cards[idx].content.internal_state = ASR_INTERNAL_STATES.ERROR;
    },
    [setKnownVocabAndGetNew.fulfilled]: (state, action) => {
      const { idx, vocab } = action.payload;
      console.log('setKnownVocabAndGetNew.fulfilled:', idx);
      console.log(action);
      state.vocabList[idx] = vocab;
      state.vocabList[idx].loading_state = false;
    },
    [setKnownVocabAndGetNew.pending]: (state, action) => {
      const { idx } = action.meta.arg;
      console.log('setKnownVocabAndGetNew.pending:', idx);
      state.vocabList[idx].loading_state = true;
    },
    [setKnownVocabAndGetNew.rejected]: (state, action) => {
      const { idx } = action.payload;
      console.log('setKnownVocabAndGetNew.rejected:', action.error);
      state.vocabList[idx].error = action.error;
      state.vocabList[idx].loading_state = true;
    },
  },

});

export const {
  completeCardAction,
  startCardAction,
  setVocabASRResult,
  setFeedbackLoadingState,
  resetFeedback,
  startRecording,
  completeASR,
  setASRText,
  interruptASR,
  setVocabList,
  setExerciseDetails,
  setLanguageDetails,
} = lessonSlice.actions;
// export const vocabInput = (state) => state.vocab.Ids;
// export const vocabContent = (state) => state.vocab.vocabContent;
// export const vocabContentLoaded = (state) => state.vocab.vocabContentLoaded;
// export const vocabList = (state) => state.vocabList;
export default lessonSlice.reducer;
