import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

export const ASRFeedbackSpeechBubble = ({
  feedbackText, usage, user,
}) => {
  // const feedbackPaperStyle = {};
  let feedbackTypographyStyle = {};
  let IconComponent = ClearIcon;
  let correctedSentence = usage;
  if (feedbackText.length === 0 && Array.isArray(feedbackText)) {
    // feedbackPaperStyle = { border: '1px solid #37d417' };
    feedbackTypographyStyle = { color: '#37d417' };
    IconComponent = CheckIcon;
    correctedSentence = 'All correct, good job!';
  } else if (feedbackText && feedbackText.length > 0 && Array.isArray(feedbackText)) {
    // feedbackPaperStyle = { border: '1px solid #fb3741' };
    feedbackTypographyStyle = { color: '#fb3741' };
    const correctionsRegex = new RegExp(`\\b(${feedbackText.join('|')})\\b`, 'gi');
    correctedSentence = usage.replace(correctionsRegex, (match) => `<span style='font-weight:bold; color:#fb3741'>${match}</span>`);
  } else if (feedbackText === 'OK') {
    // feedbackPaperStyle = { border: '1px solid #37d417' };
    feedbackTypographyStyle = { color: '#37d417' };
    IconComponent = CheckIcon;
    correctedSentence = 'All correct, good job!';
  } else {
    // feedbackPaperStyle = { border: '1px solid #fb3741' };
    feedbackTypographyStyle = { color: '#fb3741' };
    correctedSentence = feedbackText;
  }

  return (
    <Paper
      sx={{
        padding: 2,
        wordWrap: 'break-word',
        maxWidth: '90%',
        marginLeft: user ? 'auto' : '0',
        marginRight: user ? '0' : 'auto',
        background: (theme) => theme.palette.primary.main,
        color: '#000',
        // border: feedbackPaperStyle,
        borderRadius: user ? '20px 20px 0 20px' : '20px 20px 20px 0',
      }}
      elevation={3}
    >
      <Box sx={{
        display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-start',
      }}
      >
        <IconComponent fontSize="small" sx={{ color: feedbackTypographyStyle }} />
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: correctedSentence }} />
      </Box>
    </Paper>
  );
};
