import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';

import 'styles/index.css';
import 'styles/App.css';
import './assets/fonts/Poppins/Poppins-Medium.ttf';
import { Provider } from 'react-redux';
import { store } from 'store.js';
import { router } from 'router.js';
// import dotenv from 'dotenv';
import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'theme/theme';
import reportWebVitals from './reportWebVitals.js';

// Load environment variables from .env file
// dotenv.config();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <KindeProvider
        clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
        domain={process.env.REACT_APP_KINDE_DOMAIN}
        redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URI}
        logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URI}
        isDangerouslyUseLocalStorage
      >
        <Provider store={store}>
          <Container maxWidth="md" sx={{ padding: '0px' }}>
            <RouterProvider router={router} />
          </Container>
        </Provider>
      </KindeProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
