import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

export const BaseConversationBubble = ({
  usage, translation, user, background,
}) => (
  <Paper
    sx={{
      padding: 2,
      wordWrap: 'break-word',
      marginLeft: user ? 'auto' : '0',
      marginRight: user ? '0' : 'auto',
      background: background || ((theme) => theme.palette.primary.main),
      borderRadius: user ? '20px 20px 0 20px' : '20px 20px 20px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 2,
    }}
    elevation={3}
  >
    <Stack sx={{ gap: 1, alignItems: 'flex-start' }}>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: usage }} />
      <Typography variant="subtitle1">
        {translation}
      </Typography>
    </Stack>
  </Paper>
);
