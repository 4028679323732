import styled from '@emotion/styled';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

export const StyledLinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.customColors.green.main,
  },
}));
