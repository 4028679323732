import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { setLanguageDetails } from 'reducers/lessonSlice';
import { Box, Typography } from '@mui/material';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { getUser, updateUser } from 'actions/languageModelActions';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const LanguageLevel = () => {
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const { getToken } = useKindeAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedLevel, setSelectedLevel] = useState('Beginner');
  const { user } = useSelector((state) => state.global);
  const open = Boolean(anchorEl);
  const levels = {
    A1: 'Beginner',
    A2: 'Intermediate',
    B1: 'Advanced',
  };
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  });
  useEffect(() => {
    dispatch(getUser({ getToken }));
  }, [dispatch, getToken]);

  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectLevelHandler = (levelId) => {
    setAnchorEl(null);
    console.log('Level selected:');
    // dispatch(setLanguageDetails({ levelId, levelName }));
    const updated_user = { ...user, proficiency_level: levelId };
    dispatch(updateUser({ getToken, user: updated_user }));

    setShowAlert(true);
    // setSelectedLevel(levelName);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '0px', width: '100%',
    }}
    >
      <Typography variant="h2">What is your Swedish level?</Typography>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'none',
          background: '#DDD1B5',
          color: '#000000',
          borderRadius: '10px',
          padding: '10px 20px',
          width: '100%',
        }}
      >
        {levels[user?.proficiency_level || 'A1']}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        {Object.entries(levels).map(([levelId, levelName]) => (
          <MenuItem key={levelId} onClick={() => selectLevelHandler(levelId)} disableRipple>
            {levelName}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};
