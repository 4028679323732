import { useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import beepAudio from 'assets/beep.mp3';
import { ASR_INTERNAL_STATES } from 'constants/enums';
import { completeASR, interruptASR, setASRText } from 'reducers/lessonSlice';
import { useDispatch } from 'react-redux';

const SpeechSDK = require('microsoft-cognitiveservices-speech-sdk');

export const useASRCore = ({
  state, idx,
}) => {
  const dispatch = useDispatch();
  const onComplete = () => {
    dispatch(completeASR({ idx }));
  };
  const onError = () => {
    dispatch(interruptASR({ idx }));
  };
  const onTextReceived = (asrOutput) => {
    console.log(asrOutput);
    dispatch(setASRText({ idx, asrResult: asrOutput }));
  };
  const audioContext = useRef(new AudioContext()); // Audio Context is only used for playing the beep sound
  const recognizer = useRef(null); // Speech Recognizer object

  const playBeepSound = async () => {
    // Fetch the audio file using the path provided by the import
    console.log('audio is playing');
    const response = await fetch(beepAudio);
    const arrayBuffer = await response.arrayBuffer();

    // Decode the audio data
    audioContext.current.decodeAudioData(arrayBuffer, (buffer) => {
      // Create a buffer source
      const source = audioContext.current.createBufferSource();
      source.buffer = buffer;

      // Connect the source to the context's destination (speakers)
      source.connect(audioContext.current.destination);

      // Play the sound
      source.start(0);
    }, (error) => {
      console.error('Error playing beep sound', error);
    });
  };
  const getTokenOrRefresh = async () => {
    const cookie = new Cookies();
    const speechToken = cookie.get('speech-token');

    if (speechToken === undefined) {
      try {
        const res = await axios.get('api/azure_keys');
        const { token } = res.data;
        const { region } = res.data;
        cookie.set('speech-token', `${region}:${token}`, { maxAge: 540, path: '/' });

        return { authToken: token, region };
      } catch (err) {
        console.log(err.response.data);
        return { authToken: null, error: err.response.data };
      }
    } else {
      const token_idx = speechToken.indexOf(':');
      return { authToken: speechToken.slice(token_idx + 1), region: speechToken.slice(0, token_idx) };
    }
  };

  // Helper function to get the Azure Token and build the speech config (specifies langauge, voice, bitrate etc)
  const buildSpeechConfig = async () => {
    const token = await getTokenOrRefresh();
    const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(token.authToken, token.region);
    speechConfig.speechRecognitionLanguage = 'sv-SE';
    return speechConfig;
  };
  const doInterruptASR = () => {
    recognizer.current.close();
    recognizer.current.dispose();
    recognizer.current = null;
    // audioContext.current.close();
    // audioContext.current = null;
  };
  const startASR = async () => {
    // If there is an existing ASR session, close it
    console.log('ASR Started');
    const speechConfig = await buildSpeechConfig();
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    // Playing the beep sound and add a delay before starting the ASR
    playBeepSound();
    await new Promise((resolve) => { setTimeout(resolve, 200); });
    console.log('Audio Played Started');

    recognizer.current = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    console.log('Start Recognizing');
    recognizer.current.recognizeOnceAsync((result) => {
      if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
        console.log(result.text);
        onTextReceived(result.text);
        onComplete();
      } else {
        const errormessage = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
        onTextReceived(errormessage);
        onError();
      }

      // TODO Change the asr State
    });
  };

  useEffect(() => {
    if (state === ASR_INTERNAL_STATES.STARTED_RECORDING) {
      startASR();
    } else if (state === ASR_INTERNAL_STATES.COMPLETE_ASR_GET_FEEDBACK) {
      doInterruptASR();
    }
  }, [state]);

  // a text representation of the ASR states
  const showASRStatus = () => {
    switch (state) {
      case ASR_INTERNAL_STATES.STAND_BY:
        return 'ASR-Standby';
      case ASR_INTERNAL_STATES.STARTED_RECORDING:
        return 'ASR Started...';
      case ASR_INTERNAL_STATES.ERROR:
        return 'ASR Error';
      case ASR_INTERNAL_STATES.COMPLETE_ASR_GET_FEEDBACK:
        return 'ASR Completed';
      case ASR_INTERNAL_STATES.FEEDBACK_LOADING:
        return 'ASR Feedback Loading...';
      case ASR_INTERNAL_STATES.FEEDBACK_LOADED:
        return 'ASR Feedback Loaded';
      case ASR_INTERNAL_STATES.COMPLETED:
        return 'ASR Completed';
      default:
        return 'ASR UNKNOWN STATE';
    }
  };
  return showASRStatus;
};
