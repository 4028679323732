import { useDispatch, useSelector } from 'react-redux';
import { Container, Box } from '@mui/material';
import { RecordAndConfirm } from 'commons/RecordAndConfirm';
import { getMicPermission } from 'actions/speech';
import { useEffect, useRef } from 'react';
import { BackIcon } from 'commons/BackIcon';
import { SkeletonLesson, SkeletonProgressBar } from 'animations/SkeletonLesson';
import { CARD_COMPONENTS } from './utils';
import { StyledLinearProgressBar } from './commons/StyledLinearProgressBar';

export const Lesson = () => {
  const lessonCards = useSelector((state) => state.lesson.cards);
  const currentCard = useSelector((state) => state.lesson.latestActiveCardId + 1);
  const isLoaded = useSelector((state) => state.lesson.loadingState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMicPermission());
  });
  console.log(currentCard, isLoaded);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const dependency = isLoaded === 'LOADED' ? (lessonCards[currentCard].content.internal_state || currentCard) : null;
  useEffect(scrollToBottom, [dependency]);

  return (

    <Container
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '140px',
      }}
    >
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px',
      }}
      >
        <BackIcon />
        <Box sx={{ width: '70%' }}>
          {isLoaded === 'LOADING' ? <SkeletonProgressBar /> : (
            <StyledLinearProgressBar
              variant="determinate"
              value={(100 / lessonCards.length) * currentCard}
            />
          )}
        </Box>
      </Box>
      <Box sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
      >
        {isLoaded === 'LOADED' ? lessonCards.filter((card) => card.idx <= currentCard).map(
          (card) => {
            const Component = CARD_COMPONENTS[card.type];
            return Component ? <Component key={card.idx} {...card} /> : null;
          },
        ) : null}
      </Box>
      {isLoaded === 'LOADING' ? <SkeletonLesson /> : null}
      <div ref={messagesEndRef} />
      {isLoaded === 'LOADED' && lessonCards && lessonCards[currentCard].type !== 'END_OF_LESSON_CARD' ? <RecordAndConfirm idx={lessonCards[currentCard].idx} /> : null}
    </Container>
  );
};
