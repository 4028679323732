import { CARD_TYPES } from 'constants/enums';

import { InstructionCard } from 'pages/lesson/components/instructions/InstructionCard';
import { CompletionCard } from 'pages/final/Completion';
import { ExampleUsageTTS } from './components/TTS/ExampleUsageTTS';
import { QuestionTTS } from './components/TTS/QuestionTTS';
import { VocabTTS } from './components/TTS/VocabTTS';
import { PronunciationASR } from './components/ASR/PronunciationASR';
import { QuestionASR } from './components/ASR/QuestionASR';

export const CARD_COMPONENTS = {
  [CARD_TYPES.EXAMPLE_USAGE_CARD]: ExampleUsageTTS,
  [CARD_TYPES.VOCAB_CARD]: VocabTTS,
  [CARD_TYPES.QUESTION_CARD]: QuestionTTS,
  [CARD_TYPES.ASR_AND_FEEDBACK_EXAMPLE_USAGE_CARD]: PronunciationASR,
  [CARD_TYPES.ASR_AND_FEEDBACK_QUESTION_CARD]: QuestionASR,
  [CARD_TYPES.EN_INSTRUCTION_CARD]: InstructionCard,
  [CARD_TYPES.END_OF_LESSON_CARD]: CompletionCard,
};
