import {
  Avatar,
  Box, Typography,
} from '@mui/material';
import React from 'react';
import { BackIcon } from './BackIcon';

export const Navbar = ({ pageTitle }) => (
  <Box sx={{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <BackIcon />
    <Typography variant="h1">{pageTitle}</Typography>
    <Avatar
      sx={{ position: 'absolute', right: 10 }}
      src="/broken-image.jpg"
    />
  </Box>
);
