import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { ORCHESTRATED_STATES } from 'constants/enums';
import { completeCardAction } from 'reducers/lessonSlice';

export const useTTSCore = (audioRef, state, text, onError, idx) => {
  const dispatch = useDispatch();
  const stop_TTS = () => {
    audioRef.current.pause();
  };

  const onComplete = () => {
    dispatch(completeCardAction(idx));
  };

  const start_TTS = (tts_text) => {
    axios.post('/api/tts', tts_text, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    })
      .then((response) => {
        const { data } = response;
        const url = URL.createObjectURL(data);
        audioRef.current.src = url;
        audioRef.current.onloadeddata = () => {
          audioRef.current.play();
        };
        audioRef.current.onended = () => {
          onComplete();
        };
      })
      .catch((error) => {
        onError(error);
      });
  };

  useEffect(() => {
    if (state === ORCHESTRATED_STATES.STARTED) {
      start_TTS(text);
      stop_TTS();
    } else if (state === ORCHESTRATED_STATES.INTERRUPTED) {
      stop_TTS();
    }
  }, [state, text, start_TTS, stop_TTS]);

  return audioRef;
};
