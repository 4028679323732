import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography,
} from '@mui/material';
import { MainButton } from 'commons/MainButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import theme from 'theme/theme';

export const ExerciseDialog = ({
  exercise, handleClose, handleSubmitExercise, open,
}) => (
  <>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        borderRadius: '10px',
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          <img src={exercise.image} alt={exercise.name_label} />
          <Typography variant="h1">{exercise.long_description.title}</Typography>
        </Stack>
      </DialogTitle>
      <hr style={{ margin: '0 2rem' }} />
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <Typography variant="subtitle1">
            Duration:
          </Typography>
          <Typography variant="subtitle1" component="span" fontWeight="fontWeightBold">
            {exercise.long_description.time_length}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="subtitle1">
            Difficulty level:
          </Typography>
          <Typography variant="subtitle1" component="span" fontWeight="fontWeightBold">
            {exercise.long_description.difficulty_level}
          </Typography>
        </Stack>
        <DialogContentText id="alert-dialog-description" marginTop="1rem">
          <Typography variant="body1">{exercise.long_description.text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MainButton
          type="submit"
          variant="contained"
          size="large"
          sx={{
            padding: 2,
            background: theme.palette.customColors.purple.main,
            width: '100%',
            borderRadius: '10px',
            color: 'black',
            backgroundColor: theme.palette.customColors.green.main,
            marginBottom: '0',
          }}
          onClick={handleSubmitExercise}
          autoFocus
        >
          Choose Your Words
          <KeyboardArrowRightIcon />
        </MainButton>
      </DialogActions>
    </Dialog>
  </>
);
