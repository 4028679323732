/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';

export const FinalPage = () => (
  <section className="h-screen flex flex-col justify-center gap-10 items-center background">
    <img className="w-28" src={Logo} alt="Lingoberry logo" />
    <h1 className="text-zinc-100 text-5xl">Congrats!</h1>
    <Link className="w-28 px-5 py-1.5 text-sm rounded-full text-grey text-center bg-[#f56d69]" to="/">Restart</Link>
  </section>
);
