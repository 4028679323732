/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RESET_GLOBAL_STATE } from 'reducers/globalSlice';
import { RESET_VOCAB_STATE } from 'reducers/vocabSlice';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Image from 'mui-image';
import { MainButton } from 'commons/MainButton';
import Logo from '../../assets/Logo_text_light.png';

export const StartPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login, register, getToken } = useKindeAuth();
  useEffect(() => {
    dispatch(RESET_GLOBAL_STATE());
    dispatch(RESET_VOCAB_STATE());
  }, []);
  useEffect(() => {
    // Automatically login if token is present (If the user is already logged in, redirect to vocabulary page)
    async function checkToken() {
      const token = await getToken();
      console.log('getToken', token);
      if (token) {
        navigate('/vocabulary');
      }
    }
    checkToken();
  }, [getToken]);
  return (
    <Stack sx={{
      minHeight: '100vh', justifyContent: 'center', alignItems: 'center', gap: '10px', backgroundColor: (theme) => theme.palette.customColors.background,
    }}
    >
      <Image src={Logo} alt="Lingoberry logo" width="300px" />
      <Typography variant="h2" m={2} sx={{ textAlign: 'center' }}>Start your language learning journey today, improve everyday!</Typography>
      <MainButton
        onClick={register}
        variant="contained"
        size="large"
      >
        Sign up
      </MainButton>

      <Button
        onClick={login}
        variant="text"
        size="small"
        sx={{ color: '#342538' }}
      >
        I already have an account
      </Button>
    </Stack>
  );
};
