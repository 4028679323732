import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getLessonContentNew = createAsyncThunk(
  'lesson/getLessonContent',
  async ({ getToken, lessonType, vocabList }, thunkAPI) => {
    try {
      // Make a POST request to the api endpoint with json headers and the vocabList
      // call mydomain/api/setupLessonContent
      const accessToken = await getToken();
      const response = await fetch(process.env.REACT_APP_LESSON_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ lessonType, vocabList }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const getAnswerFeedback = createAsyncThunk(
  'lesson/getAnswerFeedback',
  async ({
    getToken, idx, content,
  }, thunkAPI) => {
    const accessToken = await getToken();
    console.log('input received by getAnswerFeedback:', idx, content);
    try {
      const response = await axios.post('api/answer_evaluation', content, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log('answer evaluation response:', response.data);
      return { idx, feedback: response.data };
    } catch (error) {
      console.log(error);

      thunkAPI.dispatch(SET_ERROR(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getPronounciationFeedback = createAsyncThunk(
  'lesson/getPronounciationFeedback',
  async ({
    getToken, idx, content,
  }) => {
    const accessToken = await getToken();
    console.log('input received by getPronounciationFeedback:', idx, content);
    const response = await axios.post('api/pronounciation_evaluation', content, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).catch((err) => {
      // Log error to console as debug message
      console.log('getPronounciationFeedback error: ', idx, err);
    });
    console.log('getPronounciationFeedback response:', { idx, feedback: response.data });
    return { idx, feedback: response.data };
  },
);

export const getVocabForLesson = createAsyncThunk(
  'lesson/getVocabsForLesson',
  async ({ getToken }, thunkAPI) => {
    try {
      const accessToken = await getToken();
      const response = await axios.get('api/get_vocabs_for_lesson', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log('error', error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const setKnownVocabAndGetNew = createAsyncThunk(
  'lesson/setKnownVocabAndGetNew',
  async ({ getToken, vocabToReplace, idx }, thunkAPI) => {
    try {
      const accessToken = await getToken();
      const response = await axios.post('api/set_known_vocab_and_get_new', vocabToReplace, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return {
        idx, vocab: response.data,
      };
    } catch (error) {
      console.log('error', error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getUser = createAsyncThunk(
  'lesson/getUser',
  async ({ getToken }, thunkAPI) => {
    try {
      const accessToken = await getToken();
      const response = await axios.get('api/me', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log('error', error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  'lesson/updateUser',
  async ({ getToken, user }, thunkAPI) => {
    try {
      const accessToken = await getToken();
      const response = await axios.post('api/me', user, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log('error', error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
