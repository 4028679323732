import { createSlice } from '@reduxjs/toolkit';
import { LESSON_STEPS, SPEECH_STATES } from 'constants/enums';
import { getUser, updateUser } from 'actions/languageModelActions';
// import { playAudioAndWait } from 'actions/beepSound';

const lessonStepStateMachine = (lessonOrchestrationState) => {
  switch (lessonOrchestrationState) {
    case LESSON_STEPS.STAND_BY:
      return LESSON_STEPS.STAND_BY;

    case LESSON_STEPS.VOCAB_TTS:
      return LESSON_STEPS.EXAMPLE_USAGE_TTS;
    case LESSON_STEPS.EXAMPLE_USAGE_TTS:
      return LESSON_STEPS.EXAMPLE_USAGE_STUDENT_ASR;
    case LESSON_STEPS.EXAMPLE_USAGE_STUDENT_ASR:
      return LESSON_STEPS.EXAMPLE_USAGE_STUDENT_ASR_FEEDBACK;
    case LESSON_STEPS.EXAMPLE_USAGE_STUDENT_ASR_FEEDBACK:
      return LESSON_STEPS.STAND_BY;

    case LESSON_STEPS.SIMPLE_QNA_TTS:
      return LESSON_STEPS.SIMPLE_QNA_STUDENT_ASR;
    case LESSON_STEPS.SIMPLE_QNA_STUDENT_ASR:
      return LESSON_STEPS.SIMPLE_QNA_STUDENT_ASR_FEEDBACK;
    case LESSON_STEPS.SIMPLE_QNA_STUDENT_ASR_FEEDBACK:
      return LESSON_STEPS.STAND_BY;

    case LESSON_STEPS.ADVANCED_QNA_TTS:
      return LESSON_STEPS.ADVANCED_QNA_STUDENT_ASR;
    case LESSON_STEPS.ADVANCED_QNA_STUDENT_ASR:
      return LESSON_STEPS.ADVANCED_QNA_STUDENT_ASR_FEEDBACK;
    case LESSON_STEPS.ADVANCED_QNA_STUDENT_ASR_FEEDBACK:
      return LESSON_STEPS.STAND_BY;
    default:
      return LESSON_STEPS.STAND_BY;
  }
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: '',

    currentSlideId: 0,

    ttsContent: '',
    ttsState: SPEECH_STATES.stand_by,

    asrState: SPEECH_STATES.stand_by,
    asrIsRecognizing: false,
    asrResultBuffer: '', // Temporary buffer for ASR text. Is used by other components to copy the text to their own state.

    lessonOrchestrationState: LESSON_STEPS.STAND_BY,
    isBeepPlaying: false,
    user: null,
  },
  reducers: {
    SET_LOADING: (state, action) => {
      state.isLoading = action.payload;
    },
    SET_ERROR: (state, action) => {
      state.isError = action.payload.state;
      state.errorMessage = action.payload.message;
    },
    RESET_GLOBAL_STATE: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = '';
      state.currentSlideId = 0;
      state.ttsContent = '';
      state.ttsState = SPEECH_STATES.stand_by;
      state.asrState = SPEECH_STATES.stand_by;
      state.asrIsRecognizing = false;
      state.asrResultBuffer = '';
      state.lessonOrchestrationState = LESSON_STEPS.STAND_BY;
      state.isBeepPlaying = false;
    },
    // TTS Actions
    startedTTS: (state, action) => {
      // if there is an existing tts playing, flag the player component that
      // we need to start a new one
      state.asrResultBuffer = '';
      if (state.ttsState === SPEECH_STATES.started) {
        state.ttsState = SPEECH_STATES.restarted;
      } else {
        state.ttsState = SPEECH_STATES.started;
      }
      state.ttsContent = action.payload;
    },
    cancelledTTS: (state) => {
      state.ttsContent = '';
      state.ttsState = SPEECH_STATES.cancelled;
    },
    completeTTS: (state) => {
      state.ttsContent = '';
      state.ttsState = SPEECH_STATES.completed;
    },
    // ASR Actions
    startedASR: (state) => {
      // state.asrState = SPEECH_STATES.started;
      state.asrResultBuffer = ''; // reset ASR content buffer
      // state.isAudioPlaying = true;
    },
    cancelledASR: (state) => {
      state.asrState = SPEECH_STATES.cancelled;
      state.isAudioPlaying = false;
    },
    completedASR: (state, action) => {
      state.asrResultBuffer = action.payload;
      state.asrState = SPEECH_STATES.completed;
    },

    // Lesson orchestration actions
    setCurrentSlideId: (state, action) => {
      state.currentSlideId = action.payload;
    },
    startLesson: (state) => {
      state.lessonOrchestrationState = LESSON_STEPS.VOCAB_TTS;
    },
    takeLessonStep: (state, action) => {
      // check if action has payload
      console.log('takeLessonStep action:', action);
      if (action.payload) {
        state.lessonOrchestrationState = action.payload;
      } else {
        state.lessonOrchestrationState = lessonStepStateMachine(state.lessonOrchestrationState);
      }
    },
    setManualRecordingToggle: (state, action) => {
      state.asrResultBuffer = '';
      const typeBuffer = action.payload;
      console.log('typeBuffer:', typeBuffer);

      if (typeBuffer === 'INTRODUCTION') {
        state.lessonOrchestrationState = LESSON_STEPS.EXAMPLE_USAGE_STUDENT_ASR;
      } else if (typeBuffer === 'SIMPLE_QNA') {
        state.lessonOrchestrationState = LESSON_STEPS.SIMPLE_QNA_STUDENT_ASR;
      } else if (typeBuffer === 'ADVANCED_QNA') {
        state.lessonOrchestrationState = LESSON_STEPS.ADVANCED_QNA_STUDENT_ASR;
      }
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [updateUser.pending]: (state) => {
      state.user.pending = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  SET_LOADING, SET_ERROR, RESET_GLOBAL_STATE,
  // TTS actions
  startedTTS,
  cancelledTTS,
  completeTTS,
  // ASR releated actions
  startedASR,
  cancelledASR,
  completedASR,

  // lesson orchestration actions
  startLesson,
  takeLessonStep,
  setCurrentSlideId,
  setManualRecordingToggle,

} = globalSlice.actions;

export default globalSlice.reducer;

/// Async function to play a beep sound before ASR begins recording
// export const startASRWithBeep = createAsyncThunk('vocab/playBeepSound', async (id, { dispatch }) => {
//   console.log('start playing beep sound');
//   playAudioAndWait();
//   // wait for .2 second before dispatching the action to set isBeepPlaying to false
//   await new Promise((resolve) => { setTimeout(resolve, 200); });
//   // dispatch(setPlayBeedSound(false));
//   dispatch(startASR());
//   console.log(id);
// });
