/* eslint-disable no-nested-ternary */
// import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
// import RedoIcon from '@mui/icons-material/Redo';
// import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Image from 'mui-image';
import { useEffect, useState } from 'react';
import theme from 'theme/theme';

const { Button, Box, styled } = require('@mui/material');
const { useDispatch, useSelector } = require('react-redux');
const { completeCardAction, startRecording } = require('reducers/lessonSlice');

const ActionButton = styled(Button)(({ active, currentlyActive }) => {
  const isActive = (value) => active === value && currentlyActive === value;
  return {
    color: '#000',
    background: isActive('1st recording') || isActive('Retry or next') ? '#63C094' : theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
    borderRadius: '10px',
  };
});

export const RecordAndConfirm = ({ idx }) => {
  const dispatch = useDispatch();
  const [currentlyActive, setCurrentlyActive] = useState(false);
  const lessonCards = useSelector((state) => state.lesson.cards);
  const asrResult = useSelector((state) => state.lesson.cards[idx].content.asr_output);

  useEffect(() => {
    // activate only recording btn
    if (lessonCards[idx].type === 'ASR_AND_FEEDBACK_EXAMPLE_USAGE_CARD' || lessonCards[idx].type === 'ASR_AND_FEEDBACK_QUESTION_CARD') {
      if (asrResult === null || asrResult === '') {
        setCurrentlyActive('1st recording');
      } else {
        setCurrentlyActive('Retry or next');
      }
    } else {
      setCurrentlyActive(false); // deactivate all buttons
    }
  }, [idx, asrResult]);

  console.log('RecordAndConfirm', idx);
  return (
    <Box sx={{
      width: '100%',
      height: '135px',
      display: 'flex',
      background: theme.palette.primary.main,
      alignItems: 'center',
      justifyContent: 'space-evenly',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: '10px 10px 0 0',
    }}
    >
      <ActionButton
        disabled={currentlyActive !== 'Retry or next'}
        currentlyActive={currentlyActive}
        active="Retry or next"
        onClick={() => dispatch(startRecording({ idx }))}
        size="large"
      >
        Retry
      </ActionButton>
      <ActionButton
        disabled={currentlyActive !== '1st recording'}
        onClick={() => dispatch(startRecording({ idx }))}
        size="large"
        currentlyActive={currentlyActive}
        active="1st recording"
      >
        <Image
          src="/images/lesson/voice.svg"
          alt="microphone active"
        />
      </ActionButton>
      <ActionButton
        disabled={currentlyActive !== 'Retry or next'}
        currentlyActive={currentlyActive}
        active="Retry or next"
        onClick={() => dispatch(completeCardAction(idx))}
        size="large"
      >
        Next
        <NavigateNextIcon />
      </ActionButton>
    </Box>
  );
};
