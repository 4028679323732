import React, { useState } from 'react';
import {
  Box, Card, CardActionArea, CardMedia, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setExerciseDetails } from 'reducers/lessonSlice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import exercise_data from './exercise_data.json';
import { ExerciseDialog } from './ExerciseDialog';

export const Exercises = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmitExercise = (exercise) => {
    dispatch(setExerciseDetails({ exerciseId: exercise.id, exerciseName: exercise.name }));
    navigate('/vocabularyselector');
  };
  const [selectedExercise, setSelectedExercise] = useState(null);
  const handleClickOpen = (exercise) => {
    setSelectedExercise(exercise);
    console.log(exercise.name_label);
  };

  const handleClose = () => {
    setSelectedExercise(null);
  };
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '1rem', width: '100%', height: '100%', minHeight: '100%', alignItems: 'flex-start',
    }}
    >
      <Typography variant="h2" sx={{ alignSelf: 'flex-start' }}>Select an exercise from below:</Typography>
      <Box
        sx={{
          display: 'flex',
          background: '#E3DED3',
          alignItems: 'flex-start',
          textAlign: 'center',
          width: '100%',
          borderRadius: '10px',
          border: '1px solid #000000',
          justifyContent: 'center',
          flexWrap: 'wrap',
          columnGap: '1rem',
          padding: '1rem',
          height: '100%',
          alignContent: 'flex-start',
        }}
      >
        {exercise_data.map((exercise, index) => (
          <Box sx={{ padding: '1.3rem 0', flexGrow: '1 1 0', width: '200px' }} key={index}>
            <ExerciseDialog exercise={exercise} handleClose={handleClose} handleSubmitExercise={() => handleSubmitExercise(exercise)} open={selectedExercise === exercise} />
            <Card sx={{
              aspectRatio: '16/9',
              borderRadius: '10px',
              background: exercise.background,
            }}
            >
              <CardActionArea sx={{ height: '100%' }} onClick={() => handleSubmitExercise(exercise)}>
                <Box
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClickOpen(exercise);
                  }}
                  sx={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    margin: ' 6px 3px',
                    background: 'rgb(243 243 243/70%)',
                    borderRadius: '10px',
                    color: exercise.iconColor,
                    zIndex: '1',
                  }}
                >
                  <Typography variant="h3" sx={{ fontSize: '13px' }}>
                    <InfoOutlinedIcon fontSize="small" />
                    {' '}
                    info
                  </Typography>

                </Box>
                <CardMedia
                  component="img"
                  image={exercise.image}
                  alt={exercise.image_label}
                  sx={{ height: '50%', objectFit: 'contain' }}
                />
              </CardActionArea>
            </Card>
            <Box sx={{ width: '100%' }}>
              <Typography gutterBottom variant="h3" component="div">
                {exercise.name_label}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">{exercise.short_description}</Typography>
            </Box>
          </Box>
        ))}

      </Box>
    </Box>

  );
};
