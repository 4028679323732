import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/system';

export const MainButton = styled(MuiButton)(({ theme, background, hover }) => ({
  padding: 2,
  width: '200px',
  borderRadius: '10px',
  color: 'black',
  backgroundColor: background || theme.palette.customColors.green,
  '&:hover': {
    background: hover || '#D7EDE3',
  },
  marginBottom: '0',
  minHeight: '50px',
}));
