import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

export const Animation = ({
  size, src, animationRef, autoplay, loop,
}) => (
  <div className="w-full h-full flex flex-col justify-center">
    <Player
      autoplay={autoplay}
      ref={animationRef}
      src={src}
      speed={1}
      loop={loop}
      background="transparent"
      style={{
        height: size,
        width: size,
        background: 'transparent',
        position: 'fixed',
        bottom: 0,
        left: 0,
      }}
    />
  </div>
);
