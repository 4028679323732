/* eslint-disable no-await-in-loop */
import axios from 'axios';
import Cookie from 'universal-cookie';
import {
  startedTTS,
  cancelledTTS,
  completeTTS,
  completedASR,
  cancelledASR,
  // startedASR,
} from 'reducers/globalSlice';
import { setVocabASRResult, startRecording } from 'reducers/lessonSlice';

import beepAudio from 'assets/beep.mp3';

const SpeechSDK = require('microsoft-cognitiveservices-speech-sdk');

const audioContext = new AudioContext();
let source = null;

export const stopTTS = () => async () => {
  if (source) {
    source.stop();
    dispatch(cancelledTTS());
  }
};
export const startTTS = (text) => async (dispatch) => {
  /**
   * This function is responsible for fetching the TTS audio from the server and playing it.
   * It current downloads the entire audio file before playing it.
   * In the future, I recommend looking into MediaStream + <Audio /> element for streaming the audio
   * The the backend data is fed by building a Blob URL
   * Still need to figure out how to handle turning ReadableStream into a Blob (or streams into blob)
   */
  if (source) {
    source.stop();
    source.disconnect();
  }
  console.log('TTS: ', text);
  try {
    dispatch(startedTTS());
    const response = await fetch('/api/tts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: text,
    });
    if (!response.body) {
      throw new Error('Browser does not support ReadableStream');
    }

    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start();

    source.onended = () => {
      console.log('TTS ended', text);
      dispatch(completeTTS());
      source.disconnect();
    };
  } catch (error) {
    console.error('Error fetching and playing TTS:', error);
    // Dispatch any error handling actions here
  }
};

// Beep sound for ASR
export const playBeepSound = async () => {
  console.log('start playing beep sound');

  // Fetch the audio file using the path provided by the import
  const response = await fetch(beepAudio);
  const arrayBuffer = await response.arrayBuffer();

  // Decode the audio data
  audioContext.decodeAudioData(arrayBuffer, (buffer) => {
    // Create a buffer source
    source = audioContext.createBufferSource();
    source.buffer = buffer;

    // Connect the source to the context's destination (speakers)
    source.connect(audioContext.destination);

    // Play the sound
    source.start(0);
  }, (error) => {
    console.error('Error playing beep sound', error);
  });
};

// Create an audio player globally
let global_mic = null;

// get the token from the server, or refresh if it's expired
export async function getTokenOrRefresh() {
  const cookie = new Cookie();
  const speechToken = cookie.get('speech-token');

  if (speechToken === undefined) {
    try {
      const res = await axios.get('api/azure_keys');
      const { token } = res.data;
      const { region } = res.data;
      cookie.set('speech-token', `${region}:${token}`, { maxAge: 540, path: '/' });

      return { authToken: token, region };
    } catch (err) {
      console.log(err.response.data);
      return { authToken: null, error: err.response.data };
    }
  } else {
    const idx = speechToken.indexOf(':');
    return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
  }
}

// Helper function to get the Azure Token and build the speech config (specifies langauge, voice, bitrate etc)
const buildSpeechConfig = async () => {
  const token = await getTokenOrRefresh();
  const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(token.authToken, token.region);
  // Config for TTS
  speechConfig.speechSynthesisVoiceName = 'sv-SE-SofieNeural';
  speechConfig.speechSynthesisOutputFormat = SpeechSDK.SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3;
  // Config for ASR
  speechConfig.speechRecognitionLanguage = 'sv-SE';
  return speechConfig;
};

export const getMicPermission = () => async () => {
  await navigator.mediaDevices.getUserMedia({ audio: true });
};

export const cancelASR = () => async (dispatch) => {
  if (global_mic) {
    global_mic.close();
    global_mic = null;
  }
  dispatch(cancelledASR());
};

export const startASR = (idx) => async (dispatch) => {
  // If there is an existing ASR session, close it
  if (global_mic) {
    dispatch(cancelASR());
  }

  // dispatch(startedASR('ASR Started...'));
  dispatch(startRecording(idx));// new architecture
  console.log('ASR Started');
  const speechConfig = await buildSpeechConfig();
  const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  // Playing the beep sound and add a delay before starting the ASR
  playBeepSound();
  await new Promise((resolve) => { setTimeout(resolve, 200); });
  console.log('Audio Played Started');

  global_mic = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
  console.log('Start Recognizing');
  global_mic.recognizeOnceAsync((result) => {
    let displayText;
    if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
      displayText = `${result.text}`;
    } else {
      displayText = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
    }
    console.log(displayText, idx);
    dispatch(completedASR(displayText));
    dispatch(setVocabASRResult({ idx, asrResult: displayText })); // new architecture
    // TODO Change the asr State
  });
};
