// enumeration of three speech states: 'stopped', 'starting', 'started'
export const SPEECH_STATES = {
  stand_by: 'stand_by',
  cancelled: 'cancelled',
  started: 'started',
  restarted: 'restarted',
  completed: 'completed',
};
// Possible states of each step in the lesson (e.g. vocab TTS, example usage TTS, etc.)
export const STEP_STATES = {
  notStarted: 'notStarted',
  started: 'started',
  completed: 'completed',
};

// Enumeration of the lesson states
export const LESSON_STEPS = {
  STAND_BY: 'STAND_BY',
  VOCAB_TTS: 'VOCAB_TTS',
  EXAMPLE_USAGE_TTS: 'EXAMPLE_USAGE_TTS',
  EXAMPLE_USAGE_STUDENT_ASR: 'EXAMPLE_USAGE_STUDENT_ASR',
  EXAMPLE_USAGE_STUDENT_ASR_FEEDBACK: 'EXAMPLE_USAGE_STUDENT_ASR_FEEDBACK',
  SIMPLE_QNA_TTS: 'SIMPLE_QNA_TTS',
  SIMPLE_QNA_STUDENT_ASR: 'SIMPLE_QNA_STUDENT_ASR',
  SIMPLE_QNA_STUDENT_ASR_FEEDBACK: 'SIMPLE_QNA_STUDENT_ASR_FEEDBACK',
  ADVANCED_QNA_TTS: 'ADVANCED_QNA_TTS',
  ADVANCED_QNA_STUDENT_ASR: 'ADVANCED_QNA_STUDENT_ASR',
  ADVANCED_QNA_STUDENT_ASR_FEEDBACK: 'ADVANCED_QNA_STUDENT_ASR_FEEDBACK',

};

export const LOADING_STATES = {
  STAND_BY: 'STAND_BY',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};
// Enumeration of the card types
export const CARD_TYPES = {
  VOCAB_CARD: 'VOCAB_CARD', // e.g. "Skog"
  EN_INSTRUCTION_CARD: 'EN_INSTRUCTION_CARD', // e.g. "Repeat after the sentence"
  EXAMPLE_USAGE_CARD: 'EXAMPLE_USAGE_CARD', // e.g. "Skogen är grön"
  QUESTION_CARD: 'QUESTION_CARD', // e.g. "Vad ar skogen?"
  ASR_AND_FEEDBACK_EXAMPLE_USAGE_CARD: 'ASR_AND_FEEDBACK_EXAMPLE_USAGE_CARD', // e.g. "Your pronounciation was good"
  ASR_AND_FEEDBACK_QUESTION_CARD: 'ASR_AND_FEEDBACK_QUESTION_CARD',
  END_OF_LESSON_CARD: 'END_OF_LESSON_CARD',
};
// The five states that orchestrator uses to manage the lesson
export const ORCHESTRATED_STATES = {
  ON_HOLD: 'ON_HOLD',
  STAND_BY: 'STAND_BY',
  STARTED: 'STARTED',
  INTERRUPTED: 'INTERRUPTED',
  COMPLETED: 'COMPLETED',
};

// ASR internal states
export const ASR_INTERNAL_STATES = {
  STAND_BY: 'STAND_BY',
  STARTED_RECORDING: 'STARTED_RECORDING',
  COMPLETE_ASR_GET_FEEDBACK: 'COMPLETE_ASR_GET_FEEDBACK',
  FEEDBACK_LOADING: 'FEEDBACK_LOADING',
  FEEDBACK_LOADED: 'FEEDBACK_LOADED',
  COMPLETED: 'COMPLETED',
};
