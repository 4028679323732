import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const SkeletonVocabcard = () => (
  <Skeleton
    variant="rounded"
    sx={{
      padding: 2,
      width: '100%',
      height: '70px',
      borderRadius: '10px',
    }}
  />
);

export const SkeletonVocabSelector = () => (
  <Stack
    sx={{
      padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '2rem', textAlign: 'center', height: '100%', flexGrow: 1,
    }}
  >
    <SkeletonVocabcard />
    <SkeletonVocabcard />
    <SkeletonVocabcard />
    <div style={{ flexGrow: 1 }} />
    <Skeleton
      variant="rounded"
      sx={{
        padding: 2,
        width: '100%',
        height: '60px',
        borderRadius: '10px',
        marginBottom: '0',
      }}
    />
  </Stack>
);
