import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// import { Box } from '@mui/material';

export const SkeletonProgressBar = () => (
  <Stack>
    <Skeleton variant="rectangular" width="100%" height={8} />
  </Stack>
);

export const SkeletonLesson = () => (
  <Stack spacing={2}>
    <Skeleton variant="rounded" sx={{ borderRadius: '10px' }} width="100%" height={60} />
    <Skeleton
      variant="rounded"
      sx={{
        width: '100%',
        height: '135px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '10px 10px 0 0',
      }}
    />
  </Stack>
);
