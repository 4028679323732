import { createSlice } from '@reduxjs/toolkit';
// import { getAnswerFeedback, getPronounciationFeedback } from 'actions/languageModelActions';

// import { selectASRContentBuffer } from './globalSlice';

const initialState = {
  vocabList: [],
  vocabContent: [],
  vocabContentLoaded: false,
};

export const vocabSlice = createSlice({
  name: 'vocab',
  initialState,
  reducers: {
    SET_VOCAB_INPUT: (state, action) => {
      console.log('SET_VOCAB_INPUT action dispatched:', action.payload);
      state.vocabList = action.payload;
    },
    SET_LESSON_CONTENT_REQUEST_SUCCESS: (state, action) => {
      console.log('SET_LESSON_CONTENT_REQUEST_SUCCESS action dispatched:', action);
      state.vocabContent = action.payload;
      // const asrContentBuffer = selectASRContentBuffer(state);
      // Set the initial state of the vocabContent
      state.vocabContent.forEach((lessonSlide) => {
        if (lessonSlide.type === 'INTRODUCTION') {
          lessonSlide.ASRResult = '';
        } else if (lessonSlide.type === 'SIMPLE_QNA') {
          lessonSlide.ASRResult = '';
        } else if (lessonSlide.type === 'ADVANCED_QNA') {
          lessonSlide.ASRResult = '';
        }
        lessonSlide.feedbackIsLoading = false;
        lessonSlide.feedback = '';
      });
      state.vocabContentLoaded = true;
    },
    setVocabASRResult: (state, action) => {
      // Meant to transfer from the global ASR Buffer to individual vocabContent's ASR
      const { slideID, asrResult } = action.payload;

      state.vocabContent[slideID].ASRResult = asrResult;
    },
    RESET_VOCAB_STATE: (state) => {
      state.vocabList = [];
      state.vocabContent = [];
      state.vocabContentLoaded = false;
    },
  },
  // extraReducers: {
  //   /// Async function to call the pronounciation feedback API
  //   [getPronounciationFeedback.pending]: (state, action) => {
  //     const { id } = action.meta.arg;
  //     state.vocabContent[id].feedbackIsLoading = true;
  //   },
  //   [getPronounciationFeedback.fulfilled]: (state, action) => {
  //     console.log('getPronounciationFeedback.fulfilled:', action.payload);
  //     const { id, feedback } = action.payload;
  //     state.vocabContent[id].feedback = feedback;
  //     state.vocabContent[id].feedbackIsLoading = false;
  //   },
  //   [getPronounciationFeedback.rejected]: (state, action) => {
  //     const { id } = action.meta.arg;
  //     state.vocabContent[id].feedbackIsLoading = false;
  //   },

  //   [getAnswerFeedback.pending]: (state, action) => {
  //     const { id } = action.meta.arg;
  //     state.vocabContent[id].feedbackIsLoading = true;
  //   },
  //   [getAnswerFeedback.fulfilled]: (state, action) => {
  //     console.log('getAnswerFeedback.fulfilled:', action.payload);
  //     const { id, feedback } = action.payload;
  //     state.vocabContent[id].feedback = feedback;
  //     state.vocabContent[id].feedbackIsLoading = false;
  //   },
  //   [getAnswerFeedback.rejected]: (state, action) => {
  //     const { id } = action.meta.arg;
  //     state.vocabContent[id].feedbackIsLoading = false;
  //   },
  // },

});

export const {
  SET_VOCAB_INPUT,
  SET_LESSON_CONTENT_REQUEST,
  SET_LESSON_CONTENT_REQUEST_FAILURE,
  SET_LESSON_CONTENT_REQUEST_SUCCESS,
  RESET_VOCAB_STATE,
  setVocabASRResult,
  setFeedbackLoadingState,
  resetFeedback,

} = vocabSlice.actions;
export const vocabInput = (state) => state.vocab.vocabInput;
export const vocabContent = (state) => state.vocab.vocabContent;
export const vocabContentLoaded = (state) => state.vocab.vocabContentLoaded;
export const vocabList = (state) => state.vocab.vocabList;
export default vocabSlice.reducer;
