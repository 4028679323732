import { configureStore } from '@reduxjs/toolkit';
import { vocabSlice } from 'reducers/vocabSlice';
import { globalSlice } from 'reducers/globalSlice.js';
import { lessonSlice } from 'reducers/lessonSlice';

export const store = configureStore({

  reducer: {
    [vocabSlice.name]: vocabSlice.reducer,
    [globalSlice.name]: globalSlice.reducer,
    [lessonSlice.name]: lessonSlice.reducer,
  },
  devTools: true,
});

// export const wrapper = createWrapper(makeStore);
