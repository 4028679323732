import {
  Box, Container,
} from '@mui/material';
import { Navbar } from 'commons/Navbar';
import { Exercises } from './Exercises';
import { LanguageLevel } from './LanguageLevel';
// import { Vocabulary } from '../components_OLD/Vocabulary';

export const Main = () => (
  <Container
    sx={{
      minHeight: '100vh', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
    }}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      gap: '20px',
      padding: '10px',
      position: 'relative',
      flexGrow: 1,
    }}
    >
      <Navbar pageTitle="Select an exercise" />
      <LanguageLevel />
      <Exercises />
    </Box>
  </Container>
);
