import { useEffect } from 'react';
import { ORCHESTRATED_STATES } from 'constants/enums';
import { useDispatch, useSelector } from 'react-redux';
import { completeCardAction, startCardAction } from 'reducers/lessonSlice';
import { Box, Typography } from '@mui/material';

export const InstructionCard = ({ idx, orchestrated_state, content }) => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.lesson.cards[idx].type);
  useEffect(() => {
    if (orchestrated_state === ORCHESTRATED_STATES.STARTED) {
      dispatch(startCardAction(idx));
      if (type !== 'END_OF_LESSON_CARD') {
        setTimeout(() => {
          dispatch(completeCardAction(idx));
        }, 2000);
      }
    }
  }, [orchestrated_state]);

  return (
    <Box
      sx={{
        wordWrap: 'break-word',
        maxWidth: '80%',
      }}
      className={orchestrated_state === ORCHESTRATED_STATES.STARTED ? 'activated' : ''}
      elevation={3}
    >
      <Typography variant="body1">
        {content.instruction_text}
      </Typography>
    </Box>
  );
};
