import { Link, useLocation } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Box } from '@mui/material';

export const BackIcon = () => {
  const location = useLocation();
  const to = location.pathname === '/vocabulary' ? '/' : '/vocabulary';
  return (
    <Box sx={{ position: 'absolute', left: 10, zIndex: 1 }}>
      <Link to={to}>
        <KeyboardBackspaceOutlinedIcon />
      </Link>
    </Box>
  );
};
